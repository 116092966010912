<template>
  <ion-page>
    <ion-app>
      <sm-header></sm-header>

      <ion-content scroll="true" class="sm-app-content" :fullscreen="true" :style="`--ion-background-color: ${backgroundColor}`">
        <slot></slot>
      </ion-content>
    </ion-app>
  </ion-page>
</template>

<script>
import { IonContent, IonApp, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import SmHeader from "../common/SmHeader";

export default defineComponent({
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    }
  },

  name: "SmMasterLayout",

  components: {
    IonContent,
    SmHeader,
    IonPage,
    IonApp
  },
})
</script>

<style scoped>

</style>
