<template>
  <sm-master-layout>
    <div class="sm-scanned-no-product">
      <div class="sm-scan-qr-not-found">
        <div class="sm-scan-qr-result">
          <ion-icon :icon="alertCircle" color="danger" slot="start"></ion-icon>
          <p>Artikel nicht gefunden</p>
        </div>

        <div class="sm-scan-qr-bottom sm-scanned-no-product-footer">
          <div class="sm-scan-qr-btn-checkout">
            <ion-button @click="router.push({name: 'addresses'})" expand="block" color="primary">zur kasse</ion-button>
          </div>
          <div class="sm-scan-qr-btn-shopping-cart">
            <ion-button @click="router.push({name: 'shopping-cart'})" expand="block" color="secondary">zum warenkorb</ion-button>
          </div>
          <div class="sm-scan-qr-btn-qr-code">
            <ion-button @click="() => router.push({name: 'scan-qr'})" expand="block" fill="outline" color="secondary">qr-code scannen</ion-button>
          </div>
        </div>
      </div>
    </div>
  </sm-master-layout>
</template>

<script lang="javascript">

import { IonButton, IonIcon } from '@ionic/vue';
import { alertCircle } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import SmMasterLayout from "@/components/layout/SmMasterLayout";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'ScannedProduct',

  components: {
    SmMasterLayout,
    IonButton,
    IonIcon
  },

  setup() {
    const router = useRouter();
    return { router, alertCircle };
  },

  data () {
    return {

    }
  },

  ionViewWillEnter() {
    this.setHeaderTitle('Gescannter Artikel');
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle']),
  }
})
</script>

<style scoped>
  .sm-scan-qr-not-found {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    text-transform: uppercase;
  }

  .sm-scan-qr-result {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .sm-scan-qr-result p {
    margin-left: .5rem;
    font-weight: bold;
  }

  .sm-scanned-no-product {
    height: 70vh;
  }

  .sm-scanned-no-product-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 0 1px 18px rgba(0, 0, 0, 0.18);
    width: 100%;
  }

  .sm-scan-qr-bottom {
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  }

  .sm-scan-qr-btn-qr-code,
  .sm-scan-qr-btn-shopping-cart {
    margin-top: 12px;
  }

  .sm-scan-qr-btn-shopping-cart,
  .sm-scan-qr-btn-checkout {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
</style>
