<template>
  <ion-header className="ion-no-border sm-header">
    <ion-toolbar class="sm-container-toolbar">
      <div class="sm-toolbar-top">
        <img alt="logo" height="40" src="img/logo_white.svg">
      </div>

      <div class="sm-toolbar">
        <div class="sm-screen-title">
          <strong>{{ getHeaderTitle }}</strong>
        </div>

        <div class="sm-toolbar-right">
          <span class="sm-toolbar-status-icon" :class="{'is-online': isOnline}"></span><span>Status: {{ isOnline ? 'online' : 'offline' }}</span>
        </div>
        <div class="sm-toolbar-right">
          <ion-button v-if="isLoggedIn" @click="doLogout" expand="block" color="primary">Logout</ion-button>
        </div>
      </div>
    </ion-toolbar>

    <vue-step-progress-indicator
        v-bind:class="{ 'sm-progress': isShow }"
        :steps="[
        'Checkout Address',
        'Checkout Shipping',
        'Select Template',
        'Checkout Summary',
        'Finish',
      ]"
        :active-step="[getStep]"
        :is-reactive="false"
    />
  </ion-header>
</template>

<script>
import { defineComponent } from "vue";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import { IonHeader, IonToolbar, IonButton } from '@ionic/vue';
import VueStepProgressIndicator from "../common/SmProgress";
import { useRouter } from "vue-router";
import ApiService from '@/services/api.service';
import {LOGOUT_API_PATH} from "@/constants/api.constant";

export default defineComponent({
  name: "SmHeader",

  components: {
    IonHeader,
    IonToolbar,
    IonButton,
    VueStepProgressIndicator,
  },

  created() {
    if (this.isLoggedIn) {
      this.getSalesChannel();
    }
    this.getCustomer();
  },

  data() {
    return {
      isShow: false
    }
  },

  setup() {
    const router = useRouter();
    return { router };
  },

  computed: {
    ...mapGetters('auth', ['isOnline', 'isLoggedIn']),
    ...mapGetters('common', ['getHeaderTitle', 'getStep']),
  },

  mounted() {
    const nameRoute = this.router.currentRoute.value.name;
    if (nameRoute === "shipping" || nameRoute === "addresses" || nameRoute === "summary" || nameRoute === "finish") {
      this.isShow = true;
    }
  },

  methods: {
    ...mapActions('auth', ['getCustomer', 'getSalesChannel']),
    ...mapMutations('auth', ['removeAuth']),
    async doLogout() {
      try {
        await ApiService.post(LOGOUT_API_PATH);
      } catch (e) {
          console.log(e);
      }
      this.removeAuth();
      await this.router.push({ name: 'login', replace: true });
      window.location.reload(true);
      return;
    }
  }
})

</script>

<style scoped>
  .sm-header {
    box-shadow: 1px 0 18px rgba(0, 0, 0, 0.18);
  }

  .sm-progress {
    display: flex;
  }

  .sm-screen-title {
    text-transform: uppercase;
    font-size: 1rem;
  }

  .sm-toolbar-top {
    padding: 0.5rem 1rem;
    background: var(--ion-color-primary);
  }

  .sm-toolbar {
    background: var(--ion-color-primary-contrast);
    padding: 1.125rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sm-toolbar .title-default {
    padding: 0;
    position: relative;
    text-align: left;
    font-size: 16px;
    max-width: 75%;
    text-transform: uppercase;
  }

  .sm-toolbar .sm-toolbar-right {
    align-items: center;
    font-size: 0;
    min-width: 7rem;
  }

  .sm-toolbar .sm-toolbar-right span {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.875rem;
  }

  .sm-toolbar .sm-toolbar-status-icon {
    display: block;
    height: 10px;
    width: 10px;
    margin-right: 0.3rem;
    background: var(--ion-color-warning);
    border-radius: 50%;
  }

  .sm-toolbar .sm-toolbar-status-icon.is-online {
    background: var(--ion-color-success);
  }

</style>
